<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <!-- tabs标签栏 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in tabslist"
        :key="index"
        :label="item"
        :value="index"
      ></el-tab-pane>
    </el-tabs>
    <div class="PERInformation-from">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item
          v-if="activeName == 0 || activeName == 3"
          :label="activeName == 0 ? '机构名称' : '商家名称'"
        >
          <el-input v-model="formInline.user"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formInline.region"></el-input>
        </el-form-item>
        <el-form-item label="状态">
              <el-select v-model="finshed" clearable>
                <el-option label="已支付" :value="true"></el-option>
                <el-option label="待支付" :value="false"></el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="value1"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="cxclick">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="contentList" v-if="activeName == 0">
      <el-table
        :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
        :data="list.Orderlist"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 20px"
        highlight-current-row
        v-loading="is_loading"
      >
        <el-table-column
          prop="user_id"
          align="center"
          label="课程ID"
        ></el-table-column>
        <el-table-column
          prop="map.masterSetPriceEntity.title"
          align="center"
          label="课程标题"
        ></el-table-column>
        <!-- <el-table-column
          prop="map.masterSetPriceEntity.categories_child"
          align="center"
          label="课程类目"
        ></el-table-column> -->
        <el-table-column
          prop="course_num"
          align="center"
          label="课时数"
        ></el-table-column>
        <el-table-column
          prop="map.userinfo.nick_name"
          align="center"
          label="用户昵称"
        ></el-table-column>
        <!-- <el-table-column
          prop="map.userinfo.mobile"
          align="center"
          label="用户手机号"
        ></el-table-column> -->
        <el-table-column
          prop="map.mechanismEntity.mechanism_name"
          align="center"
          label="所属机构"
          width="150px"
        >  <template slot-scope="{ row }">
            <el-popover trigger="hover" placement="top">
              <p>{{ row.map.mechanismEntity.mechanism_name }}</p>
          <div slot="reference" class="name-wrapper">
            <div class="overhide" style="width:150px;">
                  {{ row.map.mechanismEntity.mechanism_name }}
                </div>
          </div>
        </el-popover> 
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          align="center"
          label="付款金额"
        ></el-table-column>
        <el-table-column
          prop="pay_type"
          align="center"
         label="付款方式" :formatter="Paytype"
        ></el-table-column>
        <!-- <el-table-column
          prop="source"
          align="center"
         label="来源" :formatter="Paysource"
        ></el-table-column> -->
        <el-table-column
          prop="finished_time"
          align="center"
          label="下单时间" width="200px"
        ></el-table-column>
        <el-table-column align="center" label="线下收款图片">
          <!-- <template slot-scope="scope">
            <img
              style="width: 88px; height: 49px"
              :src="scope.row.map.mechanismEntity"
              alt=""
            />
          </template> -->
        </el-table-column>
        <el-table-column align="finished" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 2" style="color: #67c23a"
              >已付款</span
            >
            <span v-if="scope.row.status == 4" style="color: #f56c6c"
              >退款</span
            >
            <span v-if="scope.row.status == 1" style="color: #409eff"
              >待付款</span
            >
            <span v-if="scope.row.status == 0" style="color: #409eff"
              >未付款</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="contentList" v-if="activeName == 1">
      <el-table
        v-loading="is_loading"
        :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
        :data="list.Orderlist"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 20px"
        highlight-current-row
      >
        <el-table-column
          prop="user_id"
          align="center"
          label="ID"
        ></el-table-column>
        <el-table-column
          prop="studycard_id"
          align="center"
          label="卡号"
        ></el-table-column>
        <el-table-column
          prop="map.userinfo.nick_name"
          align="center"
          label="用户昵称"
        ></el-table-column>
        <el-table-column
          prop="map.userinfo.mobile"
          align="center"
          label="用户手机号"
        ></el-table-column>
        <el-table-column
          prop="amount"
          align="center"
          label="付款金额"
        ></el-table-column>
        <el-table-column
          prop="pay_type"
          align="center"
         label="付款方式" :formatter="Paytype"
        ></el-table-column>
        <el-table-column
          prop="source"
          align="center"
         label="来源" :formatter="Paysource"
        ></el-table-column>
        <el-table-column
          prop="finished_time"
          align="center"
          label="下单时间" width="200px"
        ></el-table-column>
        <el-table-column prop="city" align="center" label="线下收款图片">
          <!-- <template>
            <img
              style="width: 88px; height: 49px"
              src="/public/imgs/logo.png"
              alt=""
            />
          </template> -->
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 2" style="color: #67c23a"
              >已付款</span
            >
            <span v-if="scope.row.status == 4" style="color: #f56c6c"
              >退款</span
            >
            <span v-if="scope.row.status == 1" style="color: #409eff"
              >待付款</span
            >
            <span v-if="scope.row.status == 0" style="color: #409eff"
              >待付款</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="contentList" v-if="activeName == 2">
      <el-table
        v-loading="is_loading"
        :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
        :data="list.Orderlist"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 20px"
        highlight-current-row
      >
        <el-table-column
          prop="user_id"
          align="center"
          label="交易ID"
        ></el-table-column>
        <el-table-column
          prop="map.userinfo.nick_name"
          align="center"
          label="用户昵称"
        ></el-table-column>
        <el-table-column
          prop="map.userinfo.mobile"
          align="center"
          label="用户手机号"
        ></el-table-column>
        <el-table-column
          prop="course_num"
          align="center"
          label="购买数量"
        ></el-table-column>
        <el-table-column
          prop="amount"
          align="center"
          label="付款金额"
        ></el-table-column>
        <el-table-column
          prop="pay_type"
          align="center"
         label="付款方式" :formatter="Paytype"
        ></el-table-column>
        <el-table-column
          prop="source"
          align="center"
         label="来源" :formatter="Paysource"
        ></el-table-column>
        <el-table-column
          prop="finished_time"
          align="center"
          label="下单时间" width="200px"
        ></el-table-column>
        <el-table-column prop="city" align="center" label="线下收款图片">
          <!-- <template>
            <img
              style="width: 88px; height: 49px"
              src="/public/imgs/logo.png"
              alt=""
            />
          </template> -->
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 2" style="color: #67c23a"
              >已付款</span
            >
            <span v-if="scope.row.status == 4" style="color: #f56c6c"
              >退款</span
            >
            <span v-if="scope.row.status == 1" style="color: #409eff"
              >待付款</span
            >
            <span v-if="scope.row.status == 0" style="color: #409eff"
              >待付款</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="contentList" v-if="activeName == 3">
      <el-table
        v-loading="is_loading"
        :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
        :data="list.Orderlist"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 20px"
        highlight-current-row
      >
        <el-table-column
          prop="user_id"
          align="center"
          label="交易ID"
        ></el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="商家名称"
        ></el-table-column>
        <el-table-column
          prop="map.userinfo.nick_name"
          align="center"
          label="用户昵称"
        ></el-table-column>
        <el-table-column
          prop="map.userinfo.mobile"
          align="center"
          label="用户手机号"
        ></el-table-column>
        <el-table-column
          prop="city"
          align="center"
          label="商品名称"
        ></el-table-column>
        <el-table-column
          prop="course_num"
          align="center"
          label="购买数量"
        ></el-table-column>
        <el-table-column
          prop="amount"
          align="center"
          label="付款金额"
        ></el-table-column>
        <el-table-column
          prop="pay_type"
          align="center"
         label="付款方式" :formatter="Paytype"
        ></el-table-column>
        <el-table-column
          prop="source"
          align="center"
         label="来源" :formatter="Paysource"
        ></el-table-column>
        <el-table-column
          prop="finished_time"
          align="center"
          label="下单时间" width="200px"
        ></el-table-column>
        <el-table-column prop="city" align="center" label="线下收款图片">
          <!-- <template>
            <img
              style="width: 88px; height: 49px"
              src="/public/imgs/logo.png"
              alt=""
            />
          </template> -->
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 2" style="color: #67c23a"
              >已付款</span
            >
            <span v-if="scope.row.status == 4" style="color: #f56c6c"
              >退款</span
            >
            <span v-if="scope.row.status == 1" style="color: #409eff"
              >待付款</span
            >
            <span v-if="scope.row.status == 0" style="color: #409eff"
              >待付款</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="contentList" v-if="activeName == 4">
      <el-table
        v-loading="is_loading"
        :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
        :data="list.Orderlist"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 20px"
        highlight-current-row
      >
        <el-table-column
          prop="user_id"
          align="center"
          label="交易ID"
        ></el-table-column>
        <el-table-column
          prop="flowing_no"
          align="center"
          label="订单号"
        ></el-table-column>
        <el-table-column
          prop="map.userinfo.nick_name"
          align="center"
          label="用户昵称"
        ></el-table-column>
        <el-table-column
          prop="studycard_id"
          align="center"
          label="用户卡号"
        ></el-table-column>
        <el-table-column
          prop="map.userinfo.mobile"
          align="center"
          label="用户手机号"
        ></el-table-column>
        <el-table-column
          prop="course_num"
          align="center"
          label="充值权益点数"
        ></el-table-column>
        <el-table-column
          prop="amount"
          align="center"
          label="付款金额"
        ></el-table-column>
        <el-table-column
          prop="pay_type"
          align="center"
         label="付款方式" :formatter="Paytype"
        ></el-table-column>
        <el-table-column
          prop="source"
          align="center"
         label="来源" :formatter="Paysource"
        ></el-table-column>
        <el-table-column
          prop="finished_time"
          align="center"
          label="下单时间" width="200px"
        ></el-table-column>
        <el-table-column prop="city" align="center" label="线下收款图片">
          <template>
            <img
              style="width: 88px; height: 49px"
              src="/public/imgs/logo.png"
              alt=""
            />
            <samp style="color: #409eff" @click="dialogVisible = true"
              >补录</samp
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 2" style="color: #67c23a"
              >已付款</span
            >
            <span v-if="scope.row.status == 4" style="color: #f56c6c"
              >退款</span
            >
            <span v-if="scope.row.status == 1" style="color: #409eff"
              >待付款</span
            >
            <span v-if="scope.row.status == 0" style="color: #409eff"
              >待付款</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
      title="补录线下收款"
    >
      <div class="dialognr-box">
        <div class="dialognr-1">图片</div>
        <el-upload
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">添加图片</div>
          <div class="el-upload__tip" slot="tip">
            点击上传图片文件，最多上传一张
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 分页 -->
    <paging-fy
      @currentPageChange="changeCurrentPage"
      :currentPage="currentPage"
      :total="payTotal"
    ></paging-fy>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: {
        Orderlist: [],
      },
      is_loading: true,
      finshed:true,
      dialogVisible: false,
      tabslist: [
        "课程订单",
        "会员卡订单",
        "消费券订单",
        "休闲生活订单",
        "权益点充值订单",
      ],
      payTotal: 0,
      value1: [],
      formInline: {
        user: "",
        region: "",
      },
      activeName: 0,
      nav: { firstNav: "财务管理中心", secondNav: "订单管理" },
      currentPage: 1,
      orders: [
        {
          value: "study_card",
          label: "课程购买",
        },
        {
          value: "member",
          label: "会员卡",
        },
        {
          value: "coupon",
          label: "券购买",
        },
        {
          value: "商户券核销",
          label: "商户券核销",
        },
        {
          value: "point",
          label: "点数充值",
        },
      ],
    };
  },
  mounted() {
    this.getRefoundList();
  },
  methods: {
    Paytype(row,column){
      let type =row.pay_type
      if(type == 'ali'){
        return '支付宝'
      }else if(type == 'wx'){
        return '微信'
      }else{
        return type
      }
    },
    Paysource(row){
      let type =row.source
      if(type == 'sport'){
        return '小程序'
      }else{
        return type
      }
    },
    handleClick() {
      this.currentPage = 1;
      this.getRefoundList();
    },
    cxclick() {
      this.getRefoundList();
    },
    //查询订单
    getRefoundList() {
      this.is_loading = true;
      let url = "/user/rechargerecord/queryByMessage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        rcharge_type: this.orders[this.activeName].value,
        is_teach_paypal: true,
        finished:this.finshed
      };
      if (this.value1.length == 2) {
        if (this.value1[0] == this.value1[1]) {
          //时间一样查询当天
          let aa = new Date(this.value1[0]);
          let bb = new Date(aa.toLocaleDateString());
          let cc = new Date(aa.setDate(aa.getDate() + 1));
          let a =
            bb.getFullYear() +
            "-" +
            (bb.getMonth() + 1 < 10 ? "0" : "") +
            (bb.getMonth() + 1) +
            "-" +
            (bb.getDate() < 10 ? "0" : "") +
            bb.getDate() +
            " " +
            "00:00:00";
          let b =
            cc.getFullYear() +
            "-" +
            (cc.getMonth() + 1 < 10 ? "0" : "") +
            (cc.getMonth() + 1) +
            "-" +
            (cc.getDate() < 10 ? "0" : "") +
            cc.getDate() +
            " " +
            "00:00:00";
          params.start_time = a;
          params.end_time = b;
        } else {
          params.start_time = this.value1[0]; //时间
          params.end_time = this.value1[1];
        }
      }
      if (this.formInline.region) {
        params.login_name = this.formInline.region; //手机号
      }
      if (this.formInline.user) {
        params.mechanism_name = this.formInline.user; //机构名
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.payTotal = res.data.data.total;
          this.list.Orderlist = res.data.data.rows;
          this.is_loading = false;
          this.formInline.region = "";
          this.formInline.user = "";
          this.value1 = "";
        });
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getRefoundList();
    },
    handleClose(done) {
      done();
    },
  },
};
</script>

<style lang="less">
.cell {
  text-align: center;
}
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;

  .el-form-item {
    width: 40%;
  }

  .cell {
    margin: 0 auto;
  }
}

.dialognr-box {
  font-size: 14px;
  display: flex;

  .dialognr-1 {
    margin-right: 21px;
  }

  .el-upload-dragger {
    width: 80px;
    height: 77px;

    .el-icon-upload {
      margin: 0;
    }
  }
}
.overhide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
